import React from "react";
import Img from "gatsby-image"
import { withTranslation } from "react-i18next";
import SmartForm from "../SmartForm"
import Heading from "../Heading"
import './carrier-deputy-chef.scss'
class CarrierDeputyChef extends React.Component {

    render() {

        const { data, content, t } = this.props
        return(
            <div id="carrier-deputy-chef" class="blog-area blog-no-sidebar pt-100 pb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 blog-post-item ml-auto mr-auto">
                            <div class="blog-wrapper blog-details">
                                <div class="blog-content">
                                    <Heading title={data.pageTitle} subtitle={data.pageSubtitle} className="mb-50" />
                                    <div class="blog-img col-xs-12 mb-50">
                                        <Img sizes={data.image1.childImageSharp.fluid} />
                                    </div>
                                    <div dangerouslySetInnerHTML={{__html: content}} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row question-area pb-50">
                        <div class="col-lg-8 ml-auto mr-auto">
                            <h3>{t('You_can_apply_here_too')}</h3>           
                            <SmartForm 
                                successTitle={t('your_application_has_been_successfully_sent')}
                                successMessage={t('our_colleagues_will_contact_you_as_soon_as_possible')} 
                                formClassName="Konyhafonok-helyettes-form" 
                                formName="Konyhafonok-helyettes-form" 
                                mailSubject="Jelentkezés érkezett Konyhafőnök helyettes pozícióra"
                                stateProps={["name", "email", "phone", "message"]}
                                defaultMessageFields={["name", "email"]}>
                                <div class="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="single-input form-group">
                                            <input name="name" placeholder={t('name')} type="text" required />
                                        </div>  
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="single-input form-group">
                                            <input name="email" type="email" placeholder={t('email')} required />
                                        </div>  
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="single-input select date form-group">
                                            <input name="phone" autocomplete="off" type="tel" placeholder={t('phone')} required />
                                        </div>  
                                    </div>
                                    <div className="col-md-12">
                                        <div className="single-input form-group">
                                            <textarea className="form-control" name="message" placeholder={t('my_message')} />
                                        </div>  
                                    </div>
                                    <div className="col-sm-12">
                                        <button className="sent-btn form-group col-lg-auto" type="submit">
                                            {t('send_job_application')}
                                        </button>
                                    </div>
                                </div>
                            </SmartForm>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(CarrierDeputyChef)